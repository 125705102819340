body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bd-cheatsheet {
  padding-top: 100px;
  padding-bottom: 30px;
}

.footer {
  border-top: 1px solid #444444;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.bg-paper-1 {
  background-image: url(../img/paper-1.jpg);
  background-position: center;
  background-size: cover;
}

.bg-paper-2 {
  background-image: url(../img/paper-2.jpg);
  background-position: center;
  background-size: 200px;
  min-height: 900px;
}
